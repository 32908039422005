import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const ExamplesOfRestaurant = ({ location }) => {
  const title =
    "飲食店にはTwitterのクーポン配布キャンペーンがおすすめ！認知度&来店客数アップに最適な理由を解説！"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【飲食に特化！】Twitterクーポン配布の導入事例＆実施方法を解説"
          pagedesc="Twitterでのクーポン配布キャンペーンに的を絞って、事例や効果的な実施方法を説明します。すぐに参考にできる、飲食店向けの導入事例や実績もご紹介。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/examples-of-restaurant/examples-of-restaurant.jpg"
          pagepath="/blog/examples-of-restaurant"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              飲食店にはTwitterのクーポン配布キャンペーンがおすすめ！認知度&amp;来店客数アップに最適な理由を解説！
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月14日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="テーブルの上の料理"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/examples-of-restaurant/examples-of-restaurant.jpg"
                className="w-full"
              />
              <h2>
                飲食店に特化！Twitterから店舗集客へつなげるクーポン施策とは？
              </h2>
              <p>
                ネットが普及した現代では、クーポンを手に入れるためにポストに投函されるチラシを待つ必要は無くなりました。人々はSNSやネット上でデジタルクーポンを入手・使用するようになったのです。SNSユーザが年々増加している現代において、クーポンを活用することは重要なマーケティング戦略といえます。
              </p>
              <p>
                また、
                <a
                  href="https://ictr.co.jp/report/20220517-2.html/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ICT総研の「2022年度SNS利用動向に関する調査」
                </a>
                によると、日本のSNS普及率は2022年時点で82%と発表されています。SNS利用者は年々増加傾向にあり、SNSでのマーケティングはもはや避けて通ることはできません。
              </p>
              <h2>
                デジタルクーポンで経費削減しながら、Twitterから集客していこう
              </h2>
              <p>
                上記の理由から、PARKLoTでは、SNSでクーポンをプレゼントするキャンペーンの実施を強くお勧めします。特にTwitterはリツイート機能による高い拡散性が見込めるため、クーポン配布キャンペーンとの相性が良いと言えます。
              </p>
              <p>
                デジタルクーポンのプレゼントは、紙のクーポンの印刷コストや準備コストが不要な点も、見逃せません。今までは大手飲食店にしかできなかった販促キャンペーンですが、配布枚数が限定されないデジタルクーポンなら中小規模でも導入しやすいというところもポイントです。
              </p>
              <p>
                今回の記事では、Twitterでのデジタルクーポン配布キャンペーンに的を絞って、飲食店で参考になる事例や効果的な実施方法などをご説明します。
              </p>
            </figure>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitterクーポンキャンペーンとは？
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id2" className="toc__link">
                        抽選ありで、サイト誘導・実店舗集客を促進
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        "その場で結果がわかる"インスタントウィンで、サイト誘導・実店舗集客をさらに促進
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    Twitterでクーポンを配布するメリット
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        ・リツイートによって拡散されやすい
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        ・フォロワーが増えることで認知度が高まる
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ・来店客数アップが見込める
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    PARKLoTで実施した飲食チェーンの導入事例をご紹介
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        ・A社（全国飲食チェーン）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        ・B社（地域密着型飲食チェーン）
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        ・C社（全国飲食チェーン）
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id12" className="toc__link">
                    クーポン配布キャンペーンの注意点
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        ・店舗のオペレーション方法の共有をしておく
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        ・インターネット環境が悪いと使えない場合がある
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id15" className="toc__link">
                        ・クーポンが不正利用される可能性がある
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id16" className="toc__link">
                    PARKLoTでクーポン配布キャンペーンを実施する場合
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id17" className="toc__link">
                    Twitterでクーポンをプレゼントして店舗集客を大幅にアップ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">Twitterクーポンキャンペーンとは？</h2>
              <p>
                クーポンキャンペーンとは、マーケティング戦略の一つで、クーポンやディスカウントコードをユーザにプレゼントし、ECサイトや公式サイトへの流入増加、売上増加を狙う目的のキャンペーンです。
              </p>
              <p>
                Twitterにクーポンキャンペーンを告知するツイートを投稿し、アカウントをフォロー＆リツイートした参加者に対してクーポンをDMで送信します。参加者はDMで送られてきたクーポンを店頭で見せることで、クーポンを使用することができます。
              </p>
            </div>
            <div>
              <h3 id="id2">抽選ありで、サイト誘導・実店舗集客を促進</h3>
              <p>
                クーポンキャンペーンは、抽選のあり/なしで2つに分けることができます。
              </p>
              <p>
                DMでクーポンをプレゼントするときに、参加者全員に同じクーポンを配るのではなく、複数のクーポンを用意してスペシャル感を演出することもあります。抽選のプロセスが入ることで、参加者にワクワクを感じてもらうことができます。
                <br />
                例）「5,000円分お食事券×1名、3,000円分×30名様、はずれなし！100円割引クーポンが当たる」
              </p>
              <p>
                また、抽選が入ることで、もらったクーポンを大切にしてもらいやすいという側面もあります。
                「せっかく当たったから、どんなメニューがあるのかネットでチェックしてみよう」
                「久しぶりに◯◯が食べたくなったな、クーポンあるし今夜行ってみようかな」
                と、参加者にキャンペーン参加の次の行動を促すことができるのです。
              </p>
            </div>
            <div>
              <h3 id="id3">
                "その場で結果がわかる"インスタントウィンで、サイト誘導・実店舗集客をさらに促進
              </h3>
              <p>
                クーポンキャンペーンは、クーポンをもらうタイミングで2つに分けることができます。応募するとすぐに結果とクーポンが送られるタイプ（インスタントウィン）と、キャンペーン終了後に結果が分かりクーポンが送られるタイプです。
              </p>
              <p>
                説明するまでもありませんが、キャンペーン参加率・クーポン使用率が高くなるのはインスタントウィンタイプです。Twitterで応募するとすぐに結果がわかるインスタントウィンは、抽選ツールの利用が必須となります。
              </p>
              <p>
                以下の解説や導入事例では、
                <strong>
                  最も効果が見込める、抽選あり・インスタントウィン形式のデジタルクーポンキャンペーン
                </strong>
                を前提にしています。
              </p>
            </div>
            <div>
              <h2 id="id4">Twitterでクーポンを配布するメリット</h2>
              <p>Twitterでクーポンを配布するメリットは、大きく3つあります。</p>
            </div>
            <div>
              <h3 id="id5">リツイートによって拡散されやすい</h3>
              <p>
                Twitterの一番の魅力は「リツイート」によるツイートの拡散です。この機能により、リツイートされればされるほど、アカウント認知力が高まります。クーポン配布キャンペーンはフォローに加え「リツイート」が参加の条件のため、参加者が増えれば増えるほどリツイートされ、潜在顧客へリーチしやすくなります。
              </p>
            </div>
            <div>
              <h3 id="id6">フォロワーが増えることで認知度が高まる</h3>
              <p>
                クーポン配布キャンペーンの参加条件の一つが「アカウントをフォローする」ことです。クーポンに興味がある参加者があなたのアカウントをフォローしてくれるため、参加者が増えるほどフォロワーが増えることになります。結果的に、企業アカウントとしての信用度が高まります。
              </p>
              <p>
                また、フォロワーが増えて認知度が高まることで、自社商品に関心の高いフォロワーを通し、まだ関心の薄い層へと、段階的に認知を広めていくことができます。
              </p>
            </div>
            <div>
              <h3 id="id7">来店客数アップが見込める</h3>
              <p>
                クーポンは店頭で使うことを目的としているため、クーポンの当選者を店頭へ送客する効果が期待できます。後ほど詳しく解説しますが、全国飲食チェーンの場合、無料ご招待クーポン1,000件のうち、使用数が434件でした。
                <strong>
                  当選者の約43%を店頭へと送客することに成功したのです。
                </strong>
              </p>
              <p>
                このように、クーポンを配布することでTwitterアカウントの認知を上げるだけではなく、店頭への来客数アップも大きく期待することができます。
              </p>
            </div>
            <div>
              <h2 id="id8">PARKLoTで実施した飲食チェーンの導入事例をご紹介</h2>
              <p>
                ここで、PARKLoTでご実施いただいた飲食チェーン３社を例にとって、クーポンの配布率と使用率をご紹介します。
              </p>
            </div>
            <div>
              <h3 id="id9">A社（全国飲食チェーン）</h3>
              <p>
                関東・関西を中心に25店舗を展開している飲食チェーンA社様のクーポン配布キャンペーンの事例です。応募者が27,325人、クーポンの配布率も27,000件以上と、過去最多の配布数となっています。クーポン使用率も43%と予想以上の送客に成功しました。
              </p>
              <ul className="blog__border-list">
                <li>参加者数　　　27,325人</li>
                <li>
                  無料ご招待クーポン
                  <p>配布数　　　　1,000件</p>
                  <p>使用された数　434件</p>
                  <p>使用率　　　　約43%</p>
                </li>
                <li>
                  100円引きクーポン
                  <p>配布数　　　　26,312件</p>
                  <p>使用された数　244件</p>
                  <p>使用率　　　　約1%</p>
                </li>
              </ul>
            </div>
            <div>
              <h3 id="id10">B社（地域密着型飲食チェーン）</h3>
              <p>
                30店舗ほどを構える地域密着型の飲食チェーンB社様のキャンペーン事例です。全国チェーンではないため参加者数は1,475人と少なめではありますが、使用率は最大で30%と大きな成果を上げることができました。
              </p>
              <ul className="blog__border-list">
                <li>参加者数　　　1,475人</li>
                <li>
                  引換券A
                  <p>配布数　　　　107件</p>
                  <p>使用された数　20件</p>
                  <p>使用率　　　　約19%</p>
                </li>
                <li>
                  引換券B
                  <p>配布数　　　　105件</p>
                  <p>使用された数　32件</p>
                  <p>使用率　　　　約30%</p>
                </li>
                <li>
                  30円割引券A
                  <p>配布数　　　　1,263件</p>
                  <p>使用された数　17件</p>
                  <p>使用率　　　　約1.4%</p>
                </li>
              </ul>
            </div>
            <div>
              <h3 id="id11">C社（全国飲食チェーン）</h3>
              <p>
                600店舗ほどある全国規模の飲食チェーンC社様のキャンペーン事例です。全国に店舗を運営されているだけあり、使用数が1,925件と最多になっています。
              </p>
              <ul className="blog__border-list">
                <li>参加者数　　　10,011人</li>
                <li>
                  引換券
                  <p>配布数　　　　10,003件</p>
                  <p>使用された数　1,925件</p>
                  <p>使用率　　　　約19%</p>
                </li>
              </ul>
            </div>
            <p>
              「30円引きクーポン」など数十円引きクーポンよりも、「引換券」や「無料クーポン」の使用率が何倍も高いことが、上記の事例からわかります。クーポン配布キャンペーンの設計をする場合は、思い切って引換券や無料クーポンの配布を検討することをおすすめします！
            </p>
            <div>
              <h2 id="id12">クーポン配布キャンペーンの注意点</h2>
              <p>
                Twitterで簡単に配布できるクーポン配布キャンペーンですが、実施時の注意点があります。
              </p>
            </div>
            <div>
              <h3 id="id13">店舗のオペレーション方法の共有をしておく</h3>
              <p>
                店舗スタッフへのクーポンの取り扱い方法はしっかりと共有しましょう。特に、クーポンの使用方法・有効期限・クーポンの消し込み方法などの共有がされていない場合、店頭でのやり取りに時間がかかり、顧客満足度が下がってしまう可能性があります。店頭に足を運んでいただいたお客様が最後までスムーズに食事を楽しめるように、クーポンのオペレーション方法はしっかりと共有しておきましょう。
              </p>
            </div>
            <div>
              <h3 id="id14">インターネット環境が悪いと使えない場合がある</h3>
              <p>
                クーポンの表示や消し込み機能はインターネット環境が必須です。そのため、インターネット環境が悪い場合、クーポンがうまく表示されないなどの問題が発生してしまう可能性があります。地下にある・地方にあるなどで電波状況が悪い店舗に関してはクーポンの使用は不可にするなど、事前に対策を考えておきましょう。
              </p>
            </div>
            <div>
              <h3 id="id15">クーポンが不正利用される可能性がある</h3>
              <p>
                消し込み機能がないクーポンの場合、クーポン画面のスクリーンショットを撮るなどして不正使用される場合があります。できるだけ消し込みできるクーポン管理システムを利用して、クーポンの不正利用がないように対策を取りましょう。PARKLoTのクーポン配布キャンペーンの場合は、消し込み機能付きのクーポンシステムも利用できます。
              </p>
            </div>
            <div>
              <h2 id="id16">PARKLoTでクーポン配布キャンペーンを実施する場合</h2>
              <p>
                最後に、PARKLoTのクーポン配布キャンペーン機能をご紹介します。他社のインスタントウィンツールでは、クーポン配布回数に制限がある場合がほとんどですが、PARKLoTは回数無制限で何種類でもクーポンを発行することができます。また、消し込み式や期間中無制限のクーポンなど、さまざまな種類のクーポンからお選びいただけます。
              </p>
              <p>
                Twitterでのインスタントウィン抽選、DM送信、クーポン管理システムが一括で利用できるPARKLoTを、ぜひご検討ください。
              </p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="PARKLoT DMクーポン自動配布"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/examples-of-restaurant/examples-of-restaurant-2.png"
              />
              <span className="blog__image-caption">
                PARKLoT DMクーポン自動配布
              </span>
              <p>
                また、金額面でもPARKLoTは実施しやすくなっております。他社では月額100万円以上の費用設定も珍しくありませんが、PARKLoTは初期費用無料、継続しやすい月額5万円〜です。
              </p>
              <p>
                <a
                  href="https://www.park-lot.com/product/coupon-campaign"
                  target="_blank"
                  rel="noreferrer"
                >
                  クーポン配布キャンペーン機能の資料はこちら
                </a>
                からダウンロードが可能です。
              </p>
            </div>
            <div>
              <h2 id="id17">
                Twitterでクーポンをプレゼントして店舗集客を大幅にアップ
              </h2>
              <p>
                本記事では、Twitterで行うクーポン配布キャンペーンについて解説しました。フォロー＆リツイートで景品が当たるキャンペーンは多くの企業が行っていますが、飲食店の場合は店頭への集客が期待できるクーポン配布キャンペーンがおすすめです。ぜひ検討してみませんか。
              </p>
              <p>
                PARKLoTのクーポン配布キャンペーン機能について詳しく知りたい場合は、下のボタンから無料相談をご予約ください。貴社に最適なクーポン配布キャンペーン企画をご提案させていただきます。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="momoko" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-sns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    クーポン配布はLINEとTwitterのどっちがいいの？
                    料金、拡散力、開封率を徹底比較
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign-basic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default ExamplesOfRestaurant
